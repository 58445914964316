<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('user_role.roles') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col>
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="org_id"
                label-class="text-right"
                >
                <template v-slot:label>
                  {{ $t('user_role.organization') }} &nbsp; &nbsp;
                </template>
                <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="$store.state.orgList"
                  :disabled="authOrgId > 0"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="office_type_id"
              :label="$t('user.office_type')"
              label-class="text-right"
              >
              <b-form-select
                plain
                v-model="search.office_type_id"
                :options="officeTypeList"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="office_id"
              :label="$t('user.office')"
              label-class="text-right"
              >
              <v-select name="office_id"
                v-model="search.office_id"
                label="text"
                :reduce="item => item.value"
                :options= officeList
                :placeholder="$t('globalTrans.select')"
                :filter-by="myFilter"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user_role.roles') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link :to="{ name: 'common_service.access_control.user_role_list' }" class="btn btn-primary">
              {{ $t('user_role.UserRoleList') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" sm="12"  class="user-section">
                <iq-card>
                  <template v-slot:body>
                    <h4 class="text-center">{{ $t('user_role.User List') }}</h4>
                    <hr class="mt-4"/>
                    <ValidationProvider name="Designation" rules="required|min_value:1">
                      <b-form-group
                        class="row mb-0"
                        label-cols-sm="3"
                        label-for="designation_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('user_role.designation')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.designation_id"
                          :options="desigListData"
                          id="designation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="d-flex justify-content-center" v-if="loader">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <slot v-else>
                      <table class="table mt-2" v-if="user_role.user_ids.length>0">
                        <slot v-for="(user, index) in user_role.user_ids">
                          <tr :key="index">
                            <td>{{index+1}}</td>
                            <td>
                              <b-form-radio
                                class="col-form-label-sm font-weight-bold mb-0"
                                v-model="user.checked"
                                @change="clickUserMenue(user)"
                                name="checked"
                                :unchecked-value=false
                                >
                                {{ ($i18n.locale==='bn') ? user.name_bn  :  user.name  }}
                              </b-form-radio>
                            </td>
                          </tr>
                        </slot>
                      </table>
                      <div class="panel-body text-center mt-3" v-else>
                        <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                      </div>
                    </slot>
                  </template>
                </iq-card>
              </b-col>
              <b-col lg="6" sm="12"  class="role-section">
                <iq-card style="min-height: 180px;">
                  <template v-slot:body>
                    <h4 class="text-center">{{  $t('user.roleList') }}</h4>
                    <hr class="mt-4"/>
                    <slot>
                      <h5 style="height: 29px">
                        <slot v-if="data.name">
                          {{  $t('user.roleOf') }} : <b>{{data.name}}</b>
                        </slot>
                        <slot v-else>
                          <div class="text-center text-danger">{{  $t('user.PleaseSelectUser') }}</div>
                        </slot>
                      </h5>
                      <hr/>
                      <slot v-if="role_loader">
                        Loading ......
                      </slot>
                      <slot>
                        <b-form  v-if="data.roles.length>0">
                          <slot v-for="(role, index) in data.roles">
                            <b-form-checkbox
                              class="col-form-label-sm font-weight-bold mb-0"
                              v-model="role.checked"
                              name="checked"
                              :unchecked-value=false
                              :key="index"
                              >
                              {{ ($i18n.locale==='bn') ? role.role_name_bn  :  role.role_name  }}
                            </b-form-checkbox>
                          </slot>
                        </b-form>
                        <!-- <div class="panel-body text-center mt-3" v-else>
                          <h6 class="text-danger">No Data Available !!</h6>
                        </div> -->
                      </slot>
                    </slot>
                    <b-col lg="col-lg-12 ml-0 pl-0" v-if="data.roles.length>0" style="margin-top: 18px !important;">
                      <button
                        class="btn btn-primary btn-sm"
                        v-on:click="SubmitData()"
                        >
                          <i class="fas fa-list"></i>Submit
                        </button>
                    </b-col>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { officeDesignationsApi } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      search: {
        org_id: 0,
        office_type_id: 0,
        office_id: null,
        designation_id: 0
      },
      user_role: {
        user_ids: []
      },
      data: {
        user_id: '',
        name: '',
        designation_id: 0,
        org_id: 0,
        roles: []
      },
      testId: 0,
      rows: [],
      officeList: [],
      officeTypeList: [],
      designationList: [],
      loader: false,
      role_loader: false,
      is_hit: false,
      authOrgId: 0,
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    desigListData: function () {
        const listObject = this.designationList
        const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text }
        }
        })
        return tmpList
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.UserManagement.roles
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('user_role.role_name'), class: 'text-left' },
          { label: this.$t('user_role.organization'), class: 'text-left' },
          { label: this.$t('user_role.designation'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'index' },
                { key: 'role_name_bn' },
                { key: 'org_id' },
                { key: 'designation_id' },
                { key: 'status' },
                { key: 'action' }
            ]
        } else {
            keys = [
                { key: 'index' },
                { key: 'role_name' },
                { key: 'org_id' },
                { key: 'designation_id' },
                { key: 'status' },
                { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    },
    ...mapGetters({
      commonObj: 'commonObj',
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal) {
      this.user_role.user_ids = []
      this.data.roles = []
      this.officeTypeList = this.getOfficeTypeList(newVal)
      this.setOfficeList(newVal, this.search.office_type_id)
    },
    'search.office_type_id': function (newVal) {
      this.setOfficeList(this.search.org_id, newVal)
      this.getDesignationUser()
    },
    'search.office_id': function (newVal) {
      this.getDesignationUser()
      this.setOfficeWiseDesignation(newVal)
    },
    'search.designation_id': function (newVal) {
      this.getDesignationUser()
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.search = Object.assign({}, this.search, {
        org_id: this.authOrgId
      })
    }
    this.getOfficeDesignations()
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        office_type_id: this.authUser.office_detail.office_type_id,
        office_id: this.authUser.office_detail.office_id,
        designation_id: this.authUser.office_detail.designation_id
      })
    }
  },
  methods: {
    setOfficeWiseDesignation (officeId) {
      if (this.officeDesignations.length === 0) {
        this.designationList = []
        return
      }
      const filteredOfficeDesignations = []
      const designationList = this.$store.state.commonObj.designationList
      this.officeDesignations.forEach(element => {
        if (element.office_id === officeId) {
          const tmp = designationList.find(item => item.value === element.designation_id)
          filteredOfficeDesignations.push(tmp)
        }
      })
      this.designationList = filteredOfficeDesignations
    },
    getOfficeDesignations () {
      RestApi.getData(commonServiceBaseUrl, officeDesignationsApi, {}).then(response => {
        this.officeDesignations = response.data
      })
    },
    getDesignationUser () {
      if (this.search.designation_id) {
        this.user_role.user_ids = []
        this.data.id = ''
        this.data.name = ''
        this.data.roles = []
        this.loader = true
        RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', this.search).then(response => {
          this.user_role.user_ids = response.data
          this.loader = false
          this.is_hit = true
        }, error => {
          if (error) {
            //
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      }
    },
    setOfficeList (orgId, officeTypeId) {
      this.search.office_id = 0
      let officeList = []
      if (officeTypeId) {
        //  officeList = this.$store.state.commonObj.officeList.filter(item => item.org_id === orgId && item.office_type_id === officeTypeId)
         officeList = this.$store.state.commonObj.officeList.filter(item => item.office_type_id === officeTypeId)
         this.officeList = officeList
         return
      }
      officeList = this.$store.state.commonObj.officeList.filter(item => item.org_id === orgId)
      this.officeList = officeList
    },
    setDesignationList (orgId) {
      const desigList = this.commonObj.designationList.filter(item => item.org_id === orgId)
      this.designationList = desigList
    },
    getUserRoledata () {
      this.role_loader = true
      this.data.org_id = this.search.org_id
      RestApi.getData(authServiceBaseUrl, 'role/user-role-data', this.data).then(response => {
        this.data.roles = response
        this.role_loader = false
      }, error => {
        if (error) {
          //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    clickUserMenue (user) {
      this.data.user_id = user.user_id
      this.data.name = user.name
      this.data.designation_id = this.search.designation_id
      this.getUserRoledata()
    },
    async confirmData () {
      let result = null
      this.role_loader = true
      result = await RestApi.postData(authServiceBaseUrl, 'role/assign-role', this.data)
      if (result.success) {
        this.role_loader = false
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
      }
    },
    async SubmitData () {
      this.$swal({
        title: this.$t('globalTrans.recommendentChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((resultSwal) => {
        if (resultSwal.isConfirmed) {
          this.confirmData()
        }
      })
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList
      let listObject = []
      if (orgId) {
        listObject = officeTypeList.filter(officeType => officeType.org_id === orgId)
      } else {
        listObject = officeTypeList
      }
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    }
  },
  loadRoles () {},
  loadUsers () {}
}
</script>
<style scoped>
  .user-section .iq-card {
    border: solid 1px #e6e6e6
  }
  .role-section .iq-card {
    border: solid 1px #e6e6e6
  }
</style>
